import { Container } from 'containers/styled';
import styled from 'styled-components';

export const StyledContainer = styled(Container)``;

export const FormContainer = styled.div`
  padding-bottom: 30px;
`;

export const FormItem = styled.div`
  overflow: scroll;
`;
